import React from "react"
import technologyHeader from "../../../assets/images/products/netversys/technology_header.svg"
import server from "../../../assets/images/products/netversys/server.svg"
import frameworks from "../../../assets/images/products/netversys/frameworks.svg"
import SidebarNetversys from "./Sidebar"
import SidebarNetstorsys from "../netstorsys/Sidebar"
import {Link} from 'gatsby'
import { Col, Container, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faServer,
  faDatabase
} from "@fortawesome/free-solid-svg-icons"
import {
  faLinux,
  faApple,
  faWindows
} from "@fortawesome/free-brands-svg-icons"


const Technology = ({product = "netversys"}) => {
  return (
    <>
      <section className="services-details-area ptb-100">
        <div className="container mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={technologyHeader} alt="Technologie" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">{product.toUpperCase()}</span>
                <h1 className={"pt-3"}>{product.toUpperCase()} Technologie</h1>
                <p>
                  {product.toUpperCase()} ist nach neusten Standards, Technologien und Strategien entwickelt. Der
                  browserbasierte Ansatz im Zusammenspiel mit einer plattformneutralen Laufzeitumgebung,
                  macht <Link to={product.toUpperCase() === "NETVERSYS" ? "/versandsoftware/" : "/lagerverwaltung/"}>{product.toUpperCase()}</Link> zu
                    einem System mit größter Wahlfreiheit für jede IT-Abteilung.
                </p>

                <Row className={"mt-5 p-3"}>
                  <Col md={3}>
                    <FontAwesomeIcon icon={faServer} size={"3x"} className={"text-primary"} />
                  </Col>
                  <Col md={9}>
                    Durch den Einsatz von Java und Kotlin als Entwicklungssprache und der gleichzeitigen Verbreitung der
                    Java Laufzeitumgebung auf allen relevanten CPU- bzw. Hardwareplatformen, kann {product.toUpperCase()} auf
                    fast jedem am Markt verfügbaren Server eingesetzt werden. Durch Innovationen im Deployment sind kaum
                    bis gar keine Abhängigkeiten nötig, um {product.toUpperCase()} zu installieren. Durch einen
                    integrierten Application Server (Tomcat) kann das bereitgestellte Paket sofort und ohne Umwege
                    ausgeführt und dauerhaft in Betrieb genommen werden. Auch eine Einbindung in den normalen
                    Bootprozess des Betriebssystems ist möglich.
                  </Col>
                </Row>
                <Row className={"mt-5 bg-fafafb p-3"}>
                  <Col md={3}>
                    <FontAwesomeIcon icon={faDatabase} size={"3x"} className={"text-primary"} />
                  </Col>
                  <Col md={9}>
                    {product.toUpperCase()} ist zu 100% datenbankneutral und unterstützt somit alle relevanten relationalen
                    Datenbanken am Markt. Dies bedeutet geringe Kosten durch den Wegfall der Notwendigkeit neue
                    Datenbanklizenz zu kaufen. Ohne Kundenpräferenz empfehlen wir den Einsatz von MariaDB, welche unter
                    Open-Source Lizenz steht, kostenfrei ist und höchsten technischen Ansprüchen genügt.
                  </Col>
                </Row>
                <Row className={"mt-5 p-3"}>
                  <Col md={3}>
                    <FontAwesomeIcon icon={faLinux} size={"2x"} className={"text-primary mx-2"} />
                    <FontAwesomeIcon icon={faApple} size={"2x"} className={"text-primary mx-2"} />
                    <FontAwesomeIcon icon={faWindows} size={"2x"} className={"text-primary mx-2"} />
                  </Col>
                  <Col md={9}>
                    Das Hostsystem für {product.toUpperCase()} muss als minimale Konfiguration nur eine aktuelle Java
                    Laufzeitumgebung (ab Version 8 / 11 / 17) bereitstellen. Vorhanden Datenbankserver können direkt
                    angebunden werden oder ein System wird auch auf dem Host bereitgestellt. Mit einer Docker /
                    Kubernetes Umgebung funktioniert das gesamte Deplyoment noch einfacher. Alle Abhängigkeiten werden
                    von vorkonfigurierten Containern bereitgestellt, welche direkt miteinander kommunizieren und somit
                    ein direkt einsatzfähiges System bereitstellen. Mit der containerbasierten Lösung ist die Wartung
                    des Hostsystems für die IT-Abteilung minimal und eine On-Premises Lösung auch für kleinere Firmen
                    ein Gewinn!
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={4} md={12}>
              {product === "netversys" && <SidebarNetversys/>}
              {product === "netstorsys" && <SidebarNetstorsys/>}
            </Col>
          </Row>
        </div>
      </section>
      <section className="services-area pt-4 pb-70 bg-f1f8fb">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-bullseye"} /> Im Detail</span>
          <h2>Modernes Web, Cloud & Mobile</h2>
          <Row className={"mt-5"}>
            <Col md={6} xs={12}>
              <img src={frameworks} alt={"Cloud and Mobile"} />
            </Col>
            <Col md={{ offset: 1, span: 5 }} xs={{ offset: 1, span: 11 }}>
              <p>
                Viele moderne Anwendungen sind in der heutigen Zeit webbasiert und ermöglichen damit einen schnellen
                Zugriff von überall - ohne das zunächst die Software installiert werden muss. Die Produkte von LOGENTIS
                verfolgen seit Jahren den Ansatz von mächtigen browserbasierten Benutzeroberflächen und ermöglichen eine
                unkomplizierte Nutzung der Systeme. Weiterhin minimiert der zentrale Ansatz den Wartungsaufwand
                erheblich ein, da nur ein "Programm" - der Server - aktuell gehalten werden muss. Der Zugriff der
                Anwender erfolgt ja über den Browser und greift somit immer auf die neuste Version von NETVERSYS oder
                NETSTORSYS zu.
              </p>
              <p>
                Durch moderne Browsertechnologien bieten alle Produkte eine interaktive und intuitive Benutzeroberfläche
                für jeden Anwendungsfall. Auch kundenspezifische Erweiterungen lassen sich so einfach in das bestehende
                System integrieren. Zusätzlich ist der Zugriff von Endgeräten mit den verschiedensten Formfaktoren
                möglich - vom klassischen Desktop PC, über ein Tablet bis hin zum Mobiltelefon. NETSTORSYS zum Beispiel
                bietet für das papierlose <Link to={"/lagerverwaltung/netstorsys/kommissionierung/"}>Kommissionieren</Link> und
                alle weiteren typischen Tätigkeiten eine
                speziell touch-optimierte Oberfläche.
              </p>
              <p>
                Dank des einfachen Zugriffs ist es auch kein Problem die Systeme in der "Cloud" zu hosten und zu
                verwalten. Damit ist keine IT am Standort selbst notwendig und kann einfach ausgelagert werden. Außerdem
                ist der Zugriff, beispielsweise für Recherchen des Lagerbestandes, auch von Unterwegs möglich.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="services-area pt-4 pb-70 bg-23173a">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-star"} /> Feature Highlight</span>
          <h2>Machine-to-Machine</h2>
          <Row className={"mt-5"}>
            <Col md={{ span: 5 }} xs={{ span: 12 }}>
              <p>
                Immer wichtiger wird in der vernetzten Welt die Kommunikation unterschiedlichster Systeme untereinander.
                Eine manuelle Übertragung von Daten zwischen Diesen ist kaum noch zu bewältigen oder sehr
                fehleranfällig. Daher bieten alle LOGENTIS Produkte verschiedene Schnittstellen für die
                Machine-to-Machine Kommunikation an. Sei es nun die Verbindung über HTTP via REST, GraphQL oder
                Websockets. Auch ältere Systeme können via SOAP oder einfachen FTP-Dateitransfers angesprochen werden.
                Da {product.toUpperCase()} intern auch mit REST-Endpunkten arbeiten, sind nicht nur die essentiellen
                Prozesse wie Versand, Einlagerung oder Kommissionierung über die Webservices erreichbar. Sondern auch
                einfache Operationen wie die Verwaltung von Stammdaten oder Aufträgen können darüber verarbeitet werden.
              </p>
            </Col>
            <Col md={{ span: 6, offset: 1 }} xs={12}>
              <img src={server} alt={"REST Webservices"} />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Technology
